<template>
 <div>
  <a v-if="!visible" class="customizer-trigger" @click="showDrawer(true)">
   <a-badge v-if="subProducts.data.length > 0" :showZero="false" color="green" count="5" placement="start">
    <sdFeatherIcons type="shopping-cart">
    </sdFeatherIcons>
   </a-badge>
   <sdFeatherIcons v-else type="shopping-cart">
   </sdFeatherIcons>
  </a>
  <a-drawer
   v-else
   v-model:visible="visible"
   :closable="true"
   :destroyOnClose="true"
   placement="right"
   title="Winkelmandje"
   width="50%"
  >
   <slot>
    <ProductTable>
     <div class="table-cart table-responsive">
      <a-table :columns="productTableColumns" :dataSource="productTableData" :pagination="false"/>
     </div>
     <hr v-if="subProducts.data.length > 0" style="border-top: 1px solid #F8F9FB;"/>
     <div v-if="subProducts.data.length > 0" style="text-align: end;padding-right: 155px"><span
      class="cart-single-t-price">Totaal: {{
       new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR'
       }).format(subProducts.total)
      }}
     </span></div>
    </ProductTable>
   </slot>
  </a-drawer>
 </div>
</template>
<script>
import {computed, defineComponent, reactive, ref} from 'vue';
import locale from "@/static/locale/nl.json";
import {FigureCart, ProductTable} from "@/view/orders/Style";
import {useStore} from "vuex";

const productTableColumns = [
 {
  title: 'Product',
  dataIndex: 'product',
  key: 'product',
 },
 {
  title: 'Aantal',
  dataIndex: 'quantity',
  key: 'quantity',
 },
 {
  title: 'Prijs',
  dataIndex: 'total',
  key: 'total',
  width: '150px'
 },
 {
  title: '',
  dataIndex: 'action',
  key: 'action',
  width: '50px'
 },
];

const ShoppingCard = defineComponent({
 name: 'ShoppingCard',
 components: {
  ProductTable,
 },
 setup() {
  const {state, dispatch} = useStore();
  const ShoppingCard = ref()
  const loadingShop = ref(false)
  const visible = ref(false)
  const order = reactive(computed(() => state.shop.order));
  const subProducts = reactive(computed(() => state.shop.subProducts));

  const closeDrawer = () => {
   ShoppingCard.value.onClose();
  }

  const showDrawer = (state) => {
   visible.value = state;
  }

  const removeProduct = (index) => {
   dispatch('removeSubProduct', index);
  }

  const setProductQuantity = data => {
   dispatch('setSubProductQuantity', data);
  }

  const productTableData = computed(() =>
   subProducts.value.data.map((product, index) => {
    const {id, name, quantity, imageFileURL, totalAmount, externalReference} = product;
    return {
     key: id,
     product: (
      <div class="cart-single">
       <FigureCart>
        {imageFileURL !== null ? (
         <img src={imageFileURL} alt="" style="height:100%;width:80px;"/>
        ) : (
         <img alt="" style="height:100%;width:80px;"
              src="https://shop.eismann.nl/static/de.eismann.shop.theme/dist/img/product/no-image-nl.jpg"/>
        )}
        <figcaption style="width:100%">
         <div className="cart-single__info">
          {externalReference !== null ? (
           <div>
            <span><small>Artikelnr.: {externalReference}</small></span><br/>
            <sdHeading as="h6" style="display:inline;font-size:12px;width:100%">{name}</sdHeading>
           </div>
          ) : (
           <sdHeading as="h6" style="display:inline;font-size:12px;width:100%">{name}</sdHeading>
          )}
         </div>
        </figcaption>
       </FigureCart>
      </div>
     ),
     quantity: (
      <div class="cart-single-quantity" style="min-width: 110px;">
       {quantity < 2 ? (
        <sdButton disabled="disabled" class="btn-dec" type="default"
                  onClick={() => setProductQuantity({index: index, type: 'min'})}>
         <sdFeatherIcons type="minus" size="12"/>
        </sdButton>
       ) : (
        <sdButton class="btn-dec" type="default"
                  onClick={() => setProductQuantity({index: index, type: 'min'})}>
         <sdFeatherIcons type="minus" size="12"/>
        </sdButton>
       )}
       {quantity}
       <sdButton class="btn-inc" type="default" onClick={() => setProductQuantity({index: index, type: 'plus'})}>
        <sdFeatherIcons type="plus" size="12"/>
       </sdButton>
      </div>
     ),
     total: <span class="cart-single-t-price">{new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR'
     }).format((parseInt(quantity) * parseFloat(totalAmount)))}</span>,
     action: (
      <div class="table-action">
       <sdButton
        class="btn-icon"
        to="#"
        size="default"
        type="danger"
        shape="circle"
        transparented
        onClick={() => removeProduct(index)}
       >
        <sdFeatherIcons type="trash-2" size={16}/>
       </sdButton>
      </div>
     ),
    };
   }),
  );

  return {
   locale,
   closeDrawer,
   loadingShop,
   ShoppingCard,
   order,
   visible,
   showDrawer,
   productTableColumns,
   productTableData,
   setProductQuantity,
   subProducts,
  };
 },
});

export default ShoppingCard;
</script>
<style lang="scss" scoped>
.ant-badge-count {
 z-index: 1;
 min-width: 10px !important;
 height: 10px !important;
 padding: 0px;
 color: #fff;
 font-weight: normal;
 font-size: 10px;
 line-height: 16px;
 white-space: nowrap;
 text-align: center;
 background: #ff4d4f;
 border-radius: 10px;
 box-shadow: 0 0 0 1px #fff;
}
</style>
