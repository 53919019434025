<template>
 <div>
  <Sidebar>
   <sdCards>
    <template #title>
        <span>
          <sdFeatherIcons :size="14" type="sliders"/>
          <span style="font-size: 15px">Filters</span>
        </span>
    </template>
    <SidebarSingle>
     <sdHeading as="h5">Prijsklasse</sdHeading>
     <Slider :defaultValues="[productFilters.priceFrom, productFilters.priceTill]" :max="productFilters.priceTill"
             :min="0" :step="5" range @onChange="onChange"/>
     <p class="price-range-text">&euro; {{ productFilters.priceFrom }} - &euro; {{ productFilters.priceTill }}</p>
    </SidebarSingle>
    <SidebarSingle>
     <sdHeading as="h5">Categorie</sdHeading>
     <nav>
      <ul class="atbd-category-list">
       <li v-for="(category,index) in categories"
           :key="index+ (productFilters.categoryID ? parseInt(productFilters.categoryID) : 0)">
        <a :class="category.id === productFilters.categoryID ? 'active' : ''"
           to="#"
           @click.prevent="() => onChangeCategory(category.id)">
         <span><small>{{ category.name }}</small></span>
         <span class="category-count"><small>{{ category.productCount }}</small></span>
        </a>
       </li>
      </ul>
     </nav>
    </SidebarSingle>
   </sdCards>
  </Sidebar>
  <sdCards v-if="subType === 'BATTERY'">
   <a-form
    layout="vertical"
   >
    <a-form-item label="Korting" name="discountPercentage"
    >
     <a-select
      v-model:value="discount.discountPercentage" :allowClear="false"
      :filter-option="false" :showSearch="false"
      class="sDash_fullwidth-select"
      name="discountPercentage"
      size="large"
      @change="handleBatteryDiscount">
      <a-select-option name="discountPercentage" value="0">
       0%
      </a-select-option>
      <a-select-option name="discountPercentage" value="5">
       5%
      </a-select-option>
      <a-select-option name="discountPercentage" value="10">
       10%
      </a-select-option>
     </a-select>
    </a-form-item>
   </a-form>
  </sdCards>
 </div>
</template>
<script>
import {computed, defineComponent, reactive} from 'vue';
import {useStore} from 'vuex';
import Slider from "@/components/slider/Slider";
import {SidebarSingle} from "@/view/orders/component/style";
import {useRoute} from "vue-router";
import PropTypes from "vue-types";

let Sidebar;
const FiltersShop = defineComponent({
 name: 'FiltersShop',
 components: {Slider, Sidebar, SidebarSingle},
 props: {
  subType: PropTypes.string,
 },
 setup() {
  const {dispatch, state} = useStore();
  const categories = reactive(computed(() => state.shop.categories));
  const productFilters = reactive(computed(() => state.shop.productFilters));
  const {params} = useRoute();

  const discount = reactive({
   discountPercentage: 0,
  });

  const onChange = async (value) => {
   await dispatch('setProductFilters', {
    priceFrom: value[0],
    priceTill: value[1],
    type: 'priceRange'
   })
   await dispatch('fetchShopProducts', {
    productID: params.productID,
    categoryID: productFilters.value.categoryID,
    priceFrom: productFilters.value.priceFrom,
    priceTill: productFilters.value.priceTill,
    q: productFilters.value.q,
   });
  };

  const onChangeCategory = async (value) => {
   if (!productFilters.value.categoryID || productFilters.value.categoryID !== value) {
    await dispatch('setProductFilters', {
     categoryID: value,
     type: 'category'
    })
   } else {
    await dispatch('setProductFilters', {
     categoryID: null,
     type: 'category'
    })
   }
   await dispatch('fetchShopProducts', {
    productID: params.productID,
    categoryID: productFilters.value.categoryID,
    priceFrom: productFilters.value.priceFrom,
    priceTill: productFilters.value.priceTill,
    q: productFilters.value.q,
   });
  };

  const handleBatteryDiscount = (value) => {
   dispatch('handleBatteryDiscount', value);
  };

  // const handleBatteryDiscount = (e) => {
  //  console.log('test')
  //  console.log(e.target.value)
  //  dispatch('handleBatteryDiscount', e.target.value);
  // }

  return {
   onChange,
   onChangeCategory,
   productFilters,
   categories,
   discount,
   handleBatteryDiscount,
  };
 },
});

export default FiltersShop;
</script>
<style lang="scss" scoped>
.active {
 color: #000000 !important;
 font-weight: bold;
}
</style>
